import React, { memo, useCallback } from 'react';
import "../createCardPopup.css";

const Articles = memo(({article, cardInfo, setArticle, setArticleError, articleError, arrArticle, setArrArticle}) => {
   const hanleAddArticle = useCallback(() => {
     if (article) {
        setArrArticle((prev) => [...prev, article])
        setArticle('')
     }
   },[article, setArrArticle, setArticle])

    const hadleDeleteArticle = useCallback((el) => {
      const newArrArticle = arrArticle.filter((article) => article !== el)
      setArrArticle(newArrArticle)
    },[arrArticle, setArrArticle])

    const handleKeyDown = useCallback(
        (e) => {
          if (e.key === "Enter") {
            e.preventDefault(); 
            hanleAddArticle();
          }
        },
        [hanleAddArticle]
      );


    return (
        <div className="newCardClear__input-box">
        <h4 className="newCardClear__input-box-text">
            Артикул
        </h4>
        <div className="newCardClear__input_block">
        <div className="newCardClear__input_wrapper">
            <input
                id="newCardArticle"
                name="newCardArticle"
                type="text"
                className="newCardClear__input photo"
                value={article === "" && cardInfo?.article ? cardInfo.article : article}
                onChange={(e) => {
                    setArticle(e.target.value)
                }
                }
                placeholder="Введите артикул"
                required
                onKeyDown={handleKeyDown}
            />
            <button className='addArticle' onClick={hanleAddArticle} type='button'>+</button>
            </div>
            {arrArticle && <div className='listArticles'>
                {arrArticle?.map((el) => <div key={el} className='artcl'>
                    <span>{el}</span>
                    <button type='button' onClick={() => {hadleDeleteArticle(el)}}>×</button>
                    </div>
                )}
                </div>}
            {articleError && (
                <span className="newCardClear__input_error">
                    Это поле не может быть пустым
                </span>
            )}
        </div>
    </div>
    );
});

export default Articles;