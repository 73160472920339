import React, { useEffect, useState } from "react";
import "./createCardInput.css";
import arrow_drop_down from "../../../images/dropdown/arrow_drop_down.svg";
import arrow_drop_up from "../../../images/dropdown/arrow_drop_up.svg";
import Select from "react-dropdown-select";
import { useDispatch, useSelector } from "react-redux";
import {
	setBrand_models,
	setBrands,
	setCategories,
	setColors,
	setServices,
	setTags,
} from "../../../store/newProductDataSlice";

export default function CreateCardInput({
	placeholder,
	dropdownData,
	currentProduct,
}) {
	const [values, setValues] = useState([]);
	const dispatch = useDispatch();
	const brand = useSelector((state) => state.product.brands);

	useEffect(() => {
		if (currentProduct) {
			handleChange(currentProduct);
		}
	}, [currentProduct]);

	const handleChange = (values) => {
		const data = [];
		setValues(values);

		switch (placeholder) {
			case "Выберите категорию":
				values?.forEach((value) => {
					data?.push(value.slug);
				});
				dispatch(setCategories(data));
				break;
			case "Выберите услугу":
				values?.forEach((value) => {
					data?.push(value.slug);
				});
				dispatch(setServices(data));
				break;
			case "Выберите модель":
				values?.forEach((value) => {
					data?.push(value.slug);
				});
				dispatch(setBrand_models(data));
				break;
			case "Введите название цвета":
				values?.forEach((value) => {
					data?.push(value.slug);
				});
				dispatch(setColors(data));
				break;
			case "Введите тег":
				values?.forEach((value) => {
					data?.push(value.title);
				});
				dispatch(setTags(data));
				break;
			case "Выберите марку":
				dispatch(setBrands(values));
				break;
			default:
				break;
		}
	};	
	return (
		<Select
			create={true}
			placeholder={placeholder === "Введите тег" ? placeholder : ""}
			searchable={placeholder === "Введите тег" ? true : false}
			className="createCardInput-select"
			options={dropdownData}
			multi={placeholder === "Марка авто" ? false : true}
			disabled={
				placeholder !== "Выберите модель" || brand?.length !== 0
					? false
					: true
			}
			
			valueField="id"
			labelField="title"
			values={values}
			itemRenderer={({ item, methods }) => (
				<div className="select__element">
					{item.disabled ? (
						<div className="element">{item.title}</div>
					) : (
						<div
							onClick={() => methods.addItem(item)}
							className="element">
							<input
								className="custom-checkbox"
								onChange={() => methods.addItem(item)}
								type="checkbox"
								checked={methods.isSelected(item)}
								id="checked"
								name="checked"
							/>
							<div className="element__text" htmlFor="checked">
								{placeholder === "Введите название цвета" ? (
									<div
										className="element__color"
										style={{
											backgroundColor: `${item.hex_code}`,
										}}
									/>
								) : (
									<></>
								)}
								<p>{item.title}</p>
							</div>
						</div>
					)}
				</div>
			)}
			noDataRenderer={({ state }) => (
				<p>
					Ничего не найдено
					{/* Тэг с именем "<strong>{state.search}</strong>" не найден */}
				</p>
			)}
			createNewLabel="Создать новый тэг"
			onChange={(values) => handleChange(values)}
			closeOnSelect={placeholder !== "Введите название цвета"}
			closeOnClickInput={placeholder !== "Введите название цвета"}
			dropdownHandleRenderer={({ state }) => (
				<span>
					<img
						src={state.dropdown ? arrow_drop_up : arrow_drop_down}
						alt="dropdown__item_select_icon"
					/>
				</span>
			)}
		/>
	);
}
